<template>
    <div class="score">
        <div class="opt_path mb20">
            <span @click="$router.push({ path: `/race` })">插花比赛</span>
            <span>评分</span>
        </div>
        <div class="title">{{ info.title }}</div>
        <div class="ls_flexalign_center search_part">
            <el-select v-model="params.status" size="medium" @change="(params.pageIndex = 1), getList()">
                <el-option v-for="item in statusOptions" :key="item.label" :label="item.label" :value="item.value" />
            </el-select>
            <el-select v-model="params.groupId" placeholder="全部标签" size="medium" @change="(params.pageIndex = 1), getList()">
                <el-option v-for="dict in courseList" :key="dict.id" :label="dict.name" :value="dict.id" />
            </el-select>
            <el-input v-model.trim="params.studentUserName" size="medium" placeholder="请输入姓名"></el-input>

            <el-button size="medium" plain @click="(params.pageIndex = 1), getList()">
                查询
            </el-button>
            <div class="ls_flex_1"></div>
            <el-button size="medium" type="success" @click="finish">
                已完成全部打分，通知学生分数
            </el-button>
        </div>
        <div class="stu_list">
            <div v-for="item in showList" :key="item.id" @click="select(item)" :class="{ actived: item.id === sItem.id }">
                {{ item.studentUserName }}
            </div>
            <div @click="isspread = !isspread" v-if="studentList.length > 30" class="spread">
                {{ isspread ? "收起" : `展开（${studentList.length}）` }}
            </div>
        </div>
        <div v-if="showList.length == 0" class="no_data">
            <div class="empty">
                <i class="iconfont icon-queshengye_zanwushuju"></i>
            </div>
            <p class="tc">暂无数据</p>
        </div>
        <div v-if="sItem.id && (params.status == 2 || params.status == 3)">
            <div class="scoring_name" v-if="params.status == 2">
                正在给：<span>{{ sItem.studentUserName }}</span>打分
            </div>
            <div class="ls_flex">
                <div class="scoring_info">
                    <div class="ls_flexalign_center">
                        <div>作品<br />描述</div>
                        <div>{{ sItem.competitionDesc }}</div>
                    </div>
                    <div class="img_part" v-if="sItem.competitionImages && sItem.competitionImages.length > 0">
                        <div class="img_block" :style="sItem.competitionImages[imgIndex] | imgFormat(true)"></div>
                        <div class="ls_flex_center">
                            <i class="iconfont icon-zuo-01" @click="pre" />
                            <div>
                                {{ imgIndex + 1 }}/{{ sItem.competitionImages.length }}
                            </div>
                            <i class="iconfont icon-you-01" @click="next" />
                        </div>
                    </div>
                </div>
                <div>
                    <el-table :data="sItem.scoreJson" style="width: 100%" :header-cell-style="{
                        background: 'rgb(211, 211, 211)',
                    }" :cell-style="{ background: '#F4F5FA' }">
                        <el-table-column prop="key" label="项目"></el-table-column>
                        <el-table-column label="打分">
                            <template slot-scope="scope">
                                <el-input-number v-model="scope.row.score" :min="0" :max="10" :step="0.5" :precision="2" :disabled="params.status == 3"></el-input-number>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="scoring_count">
                        <span>{{ sItem.studentUserName }}</span>
                        <span>总分：{{ totalScore }}</span>
                        <span>目前排名：{{ ranking }}</span>
                    </div>
                    <div class="btn_part">
                        <el-button type="primary" @click="confirm" v-if="params.status == 2">
                            确定分数
                        </el-button>
                        <el-button @click="lookChart">
                            查看得分排行榜
                        </el-button>
                    </div>
                </div>
            </div>
        </div>
        <chartDialog ref="chartDialog" />
    </div>
</template>

<script>
import chartDialog from "../components/chartDialog.vue"

export default {
    components: { chartDialog },
    data () {
        return {
            info: {},
            isspread: false,
            sItem: {},
            imgIndex: 0,
            params: {
                id: this.$route.query.id,
                status: 2, // （1-未提交、2-已提交未评分、3-已提交已评分、4-超时）
                groupId: "",
                studentUserName: "",

                pageIndex: 1,
                pageSize: 999,
            },
            total: 0,
            statusOptions: [
                { label: "未评分", value: 2 },
                { label: "已评分", value: 3 },
                { label: "超时", value: 4 },
            ],
            courseList: [],
            studentList: [],
            ranking: "-"
        };
    },
    created () {
        this.getList();
        this.getInfo();
        this.$ajax.post("/user/group/getList", {}).then(response => {
            this.courseList = JSON.parse(JSON.stringify(response.data));
            this.courseList.unshift({ name: "全部标签", id: "" });
        });
    },
    computed: {
        showList () {
            return !this.isspread
                ? this.studentList.slice(0, 29)
                : this.studentList;
        },
        totalScore () {
            let score = 0;
            for (let item of this.sItem.scoreJson) {
                score += item.score;
            }
            this.getRanking(score)
            return score.toFixed(1);
        },
    },
    methods: {
        getRanking (score) {
            this.$ajax.post("/competition/queryScoreRank", { id: this.$route.query.id, score }).then(res => {
                this.ranking = res.data;
            })
        },
        //获取数据
        getList () {
            this.imgIndex = 0;
            this.$ajax.post("/competition/queryRecordList", this.params).then(res => {
                this.studentList = res.list;
                if (res.list.length) {
                    this.select(res.list[0]);
                } else {
                    this.sItem = {};
                }
            });
        },
        confirm () {
            this.$ajax
                .post("/competition/correct", {
                    id: this.sItem.id,
                    scoreJson: JSON.stringify(this.sItem.scoreJson),
                })
                .then(res => {
                    this.$message({
                        type: "success",
                        message: "确认成功!",
                    });
                    this.getList();
                });
        },
        getInfo () {
            this.$ajax.get("/competition/getAnalyseStatistics", {
                params: { id: this.$route.query.id }
            }).then(response => {
                this.info = response.data;
            });
        },
        lookChart () {
            this.$refs.chartDialog.showDialog();
        },
        //结束
        finish () {
            this.$confirm(
                "<div style='text-align:center;font-size:16px;margin-bottom:10px'><div style='color:red'>请确定是否已全部完成打分</div><div style='margin-top:10px'>确定后，分值排名会同步至学生</div></div>",
                "提示",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "再检查一下",
                    dangerouslyUseHTMLString: true,
                }
            ).then(() => {
                this.$ajax
                    .get("/competition/finishCorrect?id=" + this.$route.query.id)
                    .then(res => {
                        this.$message({
                            type: "success",
                            message: "通知成功!",
                        });
                        setTimeout(() => {
                            this.$router.go(-1);
                        }, 500);
                    });
            });
        },
        //选择人
        select (item) {
            let scoreItem = JSON.parse(JSON.stringify(item))
            if (!scoreItem.scoreJson) {
                scoreItem.scoreJson = [
                    { key: "技术水平", score: 0 },
                    { key: "构成造型", score: 0 },
                    { key: "色彩搭配", score: 0 },
                    { key: "意境表达", score: 0 }
                ]
            } else {
                scoreItem.scoreJson = JSON.parse(scoreItem.scoreJson)
            }
            this.sItem = scoreItem;

        },
        pre () {
            if (this.imgIndex === 0) {
                return;
            }

            this.imgIndex--;
        },
        next () {
            if (this.imgIndex === this.sItem.competitionImages.length - 1) {
                return;
            }
            this.imgIndex++;
        },
    },
};
</script>

<style lang="scss" scoped>
.btn_part {
    margin-top: 30px;

    >button {
        width: 240px;
    }
}

.scoring_count {
    margin: 20px 0;

    span {
        display: inline-block;
        padding-right: 15px;
        font-size: 16px;

        +span {
            padding-left: 15px;
            border-left: 1px solid #999;
        }
    }
}

.scoring_info {
    border: 1px solid #999;
    margin-right: 40px;

    .ls_flexalign_center {
        >div {
            &:first-child {
                width: 55px;
                font-size: 16px;
                line-height: 1.3em;
                border-right: 1px solid rgb(204, 204, 204);
            }

            &:last-child {
                flex: 1;
                line-height: 1.3em;
                padding-left: 15px;
            }
        }
    }

    >div {
        &:first-child {
            padding: 15px 30px;
            border-bottom: 1px solid #999;
        }
    }
}

.scoring_name {
    font-size: 16px;

    span {
        color: #3598f5;
        font-size: 24px;
        margin-right: 10px;
    }
}

.ls_flex {
    margin-top: 30px;
    justify-content: center;

    >div {
        &:first-child {
            width: 700px;
        }

        &:last-child {
            width: 500px;
        }
    }
}

.img_part {
    padding: 15px 0;

    .img_block {
        width: 250px;
        height: 300px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        background-color: rgb(250, 250, 250);
        margin: 0 auto 15px auto;
    }

    i {
        width: 40px;
        line-height: 40px;
        text-align: center;
        background: #8bb229;
        color: #fff;
        border-radius: 100%;
        cursor: pointer;
        font-size: 25px;
    }

    .ls_flex_center>div {
        width: 70px;
        line-height: 40px;
        text-align: center;
        font-size: 25px;
    }
}

.search_part {
    >* {
        +* {
            margin-left: 7px;
        }

        &:first-child(1),
        &:nth-child(2) {
            width: 120px;
        }

        &:nth-child(3) {
            width: 200px;
        }

        &:nth-child(6) {
            width: 280px;
        }
    }

    margin-bottom: 15px;
}

.title {
    font-size: 50px;
    margin-bottom: 20px;
}

.score {
    padding: 30px 30px 0 0;
}

.stu_list {
    overflow: hidden;

    >div {
        float: left;
        font-size: 16px;
        padding: 10px 16px;
        min-width: 60px;
        border-radius: 20px;
        text-align: center;
        border: 1px solid rgb(179, 179, 179);
        cursor: pointer;
        margin-bottom: 15px;

        +div {
            margin-left: 15px;
        }
    }

    .actived {
        background: #8bb229;
        color: #fff;
        border-color: #8bb229;
    }

    .spread {
        border: none;
        font-size: 14px;
        padding: 10px 13px;
        margin-left: 0;
    }
}

.no_data {
    text-align: center;

    .empty {
        margin-top: 60px;
        margin-bottom: 24px;

        i {
            font-size: 130px;
            color: #999;
        }
    }

    p {
        font-size: 16px;
        color: #999999;
        margin-bottom: 139px;
    }
}
</style>
